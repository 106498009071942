<template>
  <div>
    <topBarY />
    <div class="bg">
      <img src="../../assets/common/pic/bg.jpg" alt="">
    </div>
    <div class="switchBar">
      <div class="tab cabinet" :class="{ active: switchIndex == 1 }" @click="switchClick(1)">智能无线保管柜</div>
      <div class="tab wireless" :class="{ active: switchIndex == 2 }" @click="switchClick(2)">保管柜模组设备</div>
      <div class="tab gateway" :class="{ active: switchIndex == 3 }" @click="switchClick(3)">智能网关</div>
      <router-link to="/productCenter" class="tab more">更多产品</router-link>
    </div>
    <div class="line"></div>
    <div class="switchContiner">
      <div class="continer cabinetContiner" v-if="switchIndex == 1">
        <div class="continer">
          <div class="introduce">
            <span class="tit">
              智能无线保管柜
            </span>
            <br>
            <br>
            <span class="txt">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;HLD智能保管柜系列产品是宁讯推出基于模块化设计，运用RFID射频技术<br>
              自动控制技术、工业网络技术、互联网技术系统设计的智能柜产品系列。HLD<br>
              智能保管柜基于超高频RFID扫描系统，物资识别率达到99.9%，扫描速度1～<br>
              10S可调。可根据客户需求进行灵活定制。
            </span>
            <br>
            <br>
            <br>
            <span class="tit">
              应用场景
            </span>
            <br>
            <br>
            <span class="txt">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;HLD智能保管柜可广泛应用于电力、医疗、智能工厂等仓库领域。对相关<br>
              物资工具产品可实现智能化、数字化、无人化管理
            </span>
          </div>
          <img src="../../assets/product/cabinet.png" alt="">
        </div>
      </div>
      <div class="continer wirelessContiner" v-if="switchIndex == 2">
        <div class="continer">
          <div class="introduce">
            <span class="tit">
              保管柜模组设备
            </span>
            <br>
            <br>
            <span class="txt">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;宁讯智能保管柜模组设备系列包含管理网关模组、恒温除湿模组、配电模<br>
              组、通风模组、锁控模组以及安全识别模组。系统具有丰富的控制功能，硬件<br>
              系统与控制策略可灵活配置组合，系统可配置温湿度检测模组、通风模组、恒<br>
              温除湿模组、冷凝除湿模组、凝水液位检测模组等，实现柜内环境调节、柜内<br>
              有害气体排出等多种控制功能，实现不同应用的需求。
            </span>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
          </div>
          <img src="../../assets/support/equipment.png" alt="">
        </div>
      </div>
      <div class="continer gatewayContiner" v-if="switchIndex == 3">
        <div class="continer">
          <div class="introduce">
            <span class="tit">
              智能网关
            </span>
            <br>
            <br>
            <span class="txt">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;主站管理网关是基于边缘计算与云服务为核心的智能化控制系统的核心设<br>
              备。其集控制逻辑开发，业务流程开发，人工智能运用开发，协议对接，I/O<br>
              处理，身份管理，射频标签系统管理，信息化系统对接，以及物联网数据通讯，<br>
              为一体。<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;在智能保管柜中作为核心单元，用户操作与信息化指令，通过网关实现终<br>
              端设备控制，同时将设备的状态，操作数据反馈。<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;主站管理网关强大的功能，支持智能器具柜的RFID扫描、身份识别、逻辑<br>
              控制、从站通讯与控制、通讯数据接入、屏幕显示、视频处理、硬件配置、业<br>
              务配置、业务逻辑等功能，以及设备管理和外部应用系统接入等运用场景。
              <br>
              <br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;从站控制网关是远程数据集成、I/O管控与通讯管理的设备单元。其主要功<br>
              能包括区域数据集成、主从站数据通讯，智能设备接入处理、I/O管理，身份设<br>
              备管理等。从站网关作为区域智能设备同样具备强大的系统配置功能，借住与<br>
              内嵌web界面提供便捷的管理与测试功能。
            </span>
            <br>
            <br>
            <br>
            <br>
            <br>
          </div>
          <img src="../../assets/support/gateway.png" alt="">
        </div>
      </div>
    </div>
    <baseBar />
  </div>
</template>

<script>
import topBarY from '@/components/topBarY.vue'
import baseBar from '@/components/baseBar.vue'

export default {
  name: 'Product',
  components: {
    topBarY,
    baseBar
  },
  data() {
    return {
      switchIndex: 1
    }
  },
  methods: {
    switchClick(index) {
      this.switchIndex = index
    }
  },
  created() {
    this.$nextTick(() => {
      // 1.禁用右键菜单
      document.oncontextmenu = new Function("event.returnValue=false");
      // 2.禁用鼠标选中
      document.onselectstart = new Function("event.returnValue=false");
      // 3.禁止键盘F12键
      document.addEventListener("keydown", function (e) {
        if (e.key == "F12") {
          e.preventDefault(); // 如果按下键F12,阻止事件
        }
      });
    });
  }
}
</script>

<style scoped>
.bg {
  position: relative;
  width: 1920px;
  height: 480px;
  overflow: hidden;
}

.bg img {
  position: absolute;
  top: -100px;
  width: 1920px;
}

.switchBar {
  height: 70px;
  background-color: #fff;
  text-align: center;
}

.switchBar .tab {
  display: inline-block;
  margin-right: 30px;
  margin-top: 15px;
  cursor: pointer;
  border-radius: 5px;
  width: 140px;
  height: 40px;
  background-color: #c3a35d;
  color: #f5f5f5;
  font-family: 'Microsoft Yahei';
  font-size: 14px;
  line-height: 40px;
}

.switchBar .more {
  margin-right: 0;
}

.line {
  margin: 0 auto;
  width: 1400px;
  height: 1px;
  background-color: #c3a35d;
}

.switchContiner {
  width: 1920px;
  padding: 20px;
  background-color: #f7f7f7;
}

.switchContiner .weight{
  color: #333;
}

/* 智能保管柜 */
.switchContiner .cabinetContiner {
  margin: 0 auto;
  width: 1400px;
  min-height: 600px;
  border-radius: 5px;
  background-color: #fff;
}

.switchContiner .cabinetContiner .continer {
  position: relative;
  margin: 0 auto;
  padding-top: 40px;
  width: 1000px;
}

.switchContiner .cabinetContiner .continer .tit {
  font-size: 18px;
  margin-bottom: 20px;
}

.switchContiner .cabinetContiner .continer .txt {
  color: #999;
  font-size: 14px;
  line-height: 30px;
}

.switchContiner .cabinetContiner img {
  position: absolute;
  top: 40px;
  right: 0;
  width: 400px;
}

/* 工业无线传输 */
.switchContiner .wirelessContiner {
  margin: 0 auto;
  width: 1400px;
  min-height: 500px;
  border-radius: 5px;
  background-color: #fff;
}

.switchContiner .wirelessContiner .continer {
  position: relative;
  margin: 0 auto;
  padding-top: 40px;
  width: 1000px;
}

.switchContiner .wirelessContiner .continer .tit {
  font-size: 18px;
  margin-bottom: 20px;
}

.switchContiner .wirelessContiner .continer .txt {
  color: #999;
  font-size: 14px;
  line-height: 30px;
}

.switchContiner .wirelessContiner img {
  position: absolute;
  top: 40px;
  right: 0;
  width: 320px;
}

/* 智能网管控制 */
.switchContiner .gatewayContiner {
  margin: 0 auto;
  width: 1400px;
  min-height: 600px;
  border-radius: 5px;
  background-color: #fff;
}

.switchContiner .gatewayContiner .continer {
  position: relative;
  margin: 0 auto;
  padding-top: 40px;
  width: 1000px;
}

.switchContiner .gatewayContiner .continer .tit {
  font-size: 18px;
  margin-bottom: 20px;
}

.switchContiner .gatewayContiner .continer .txt {
  color: #999;
  font-size: 14px;
  line-height: 30px;
}

.switchContiner .gatewayContiner img {
  position: absolute;
  top: 80px;
  right: 0;
  width: 320px;
}

/* 无线网管平台软件系列 */
.switchContiner .platformContiner {
  margin: 0 auto;
  width: 1400px;
  height: 600px;
  border-radius: 5px;
  background-color: #fff;
}

.switchContiner .platformContiner .continer {
  position: relative;
  margin: 0 auto;
  padding-top: 40px;
  width: 1000px;
}

.switchContiner .platformContiner .continer .tit {
  font-size: 18px;
  margin-bottom: 20px;
}

.switchContiner .platformContiner .continer .txt {
  color: #999;
  font-size: 14px;
  line-height: 30px;
}

.switchContiner .platformContiner img {
  position: absolute;
  top: 40px;
  right: 0;
  width: 400px;
}
</style>
